import {all, fork} from 'redux-saga/effects';
import {sagas as AuthSagas} from './auth/saga';
import {sagas as SpendSagas} from './spend/saga';
import {sagas as TeamSagas} from './teams/saga';
import {sagas as SettingSaga} from './settings/saga';

export default function* rootSaga() {
  yield all(AuthSagas.map(s => fork(s)));
  yield all(SpendSagas.map(s => fork(s)));
  yield all(TeamSagas.map(s => fork(s)));
  yield all(SettingSaga.map(s => fork(s)));
}
