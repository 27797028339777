import {applyMiddleware, createStore} from 'redux';
import createSagaMiddleware from "redux-saga";
import rootReducer from './reducers';
import rootSaga from "./sagas";

export function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, {}, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(rootSaga);
  return store;
}
