import {combineReducers} from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authReducer from './auth/reducer';
import spendReducer from "./spend/reducer";
import teamReducer from "./teams/reducer";
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
  menu,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  settings,
  authReducer,
  spendReducer,
  teamReducer
});

export default rootReducer;