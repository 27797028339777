export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const GOT_FITBIT_DATA =  'GOT_FITBIT_DATA';
export const RESEND_VERIFY_EMAIL =  'RESEND_VERIFY_EMAIL';

export function getUserDetails(userId) {
  return {
    type: GET_USER_DETAILS,
    userId
  }
}

export function getUserDetailsSuccess(user) {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: user
  }
}

export function getUserDetailsError(message) {
  return {
    type: GET_USER_DETAILS_ERROR,
    payload: message
  }
}

export function loginUser(user, history) {
  return {
    type: LOGIN_USER,
    payload: {user, history}
  }
}

export function loginUserSuccess(user) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: user
  }
}

export function loginUserError(message) {
  return {
    type: LOGIN_USER_ERROR,
    payload: {message}
  }
}

export function forgotPassword(forgotUserMail, history) {
  return {
    type: FORGOT_PASSWORD,
    payload: {forgotUserMail, history}
  }
}

export function forgotPasswordSuccess(forgotUserMail) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: forgotUserMail
  }
}

export function forgotPasswordError(message) {
  return {
    type: FORGOT_PASSWORD_ERROR,
    payload: {message}
  }
}

export function resetPassword({resetPasswordCode, newPassword, history}) {
  return {
    type: RESET_PASSWORD,
    payload: {resetPasswordCode, newPassword, history}
  }
}

export function resetPasswordSuccess(newPassword) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: newPassword
  }
}

export function resetPasswordError(message) {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: {message}
  }
}

export function registerUser(user) {
  return {
    type: REGISTER_USER,
    payload: user
  }
}

export function registerUserSuccess() {
  return {
    type: REGISTER_USER_SUCCESS
  }
}

export function registerUserError(message) {
  return {
    type: REGISTER_USER_ERROR,
    payload: {message}
  }
}

export function logoutUser(history) {
  return {
    type: LOGOUT_USER,
    payload: {history}
  }
}

export function gotFitbitData() {
  return {
    type: GOT_FITBIT_DATA,
  }
}

export function resendVerifyEmail() {
  return {
    type: RESEND_VERIFY_EMAIL,
  }
}