export const FETCH_TEAM_DATA = "FETCH_TEAM_DATA";
export const FETCH_TEAM_DATA_SUCCESS = "FETCH_TEAM_DATA_SUCCESS";
export const FETCH_TEAM_DATA_ERROR = "FETCH_TEAM_DATA_ERROR";
export const SET_PRICE_DETAILS = "SET_PRICE_DETAILS";
export const SUBMIT_CONTRIBUTION = "SUBMIT_CONTRIBUTION";
export const SUBMIT_CONTRIBUTION_SUCCESS = "SUBMIT_CONTRIBUTION_SUCCESS";
export const SUBMIT_CONTRIBUTION_ERROR = "SUBMIT_CONTRIBUTION_ERROR";

export function fetchTeamData(teamIds) {
  return {
    type: FETCH_TEAM_DATA,
    payload: teamIds
  }
}

export function fetchTeamDataSuccess(data) {
  return {
    type: FETCH_TEAM_DATA_SUCCESS,
    payload: data
  }
}

export function fetchTeamDataError(message) {
  return {
    type: FETCH_TEAM_DATA_ERROR,
    payload: {message}
  }
}

export function setPriceDetails(data) {
   return {
    type: SET_PRICE_DETAILS,
    payload: data
  }
}

export function submitContribution(amount, priceID) {
  return {
    type: SUBMIT_CONTRIBUTION,
    amount: amount,
    priceID: priceID
  }
}

export function submitContributionSuccess() {
  return {
    type: SUBMIT_CONTRIBUTION_SUCCESS
  }
}

export function submitContributionError(error) {
  return {
    type: SUBMIT_CONTRIBUTION_ERROR,
    message: error
  }
}