import firebase from 'firebase';
// import 'firebase/auth';
// import 'firebase/database';

// export const firebaseConfig = {
//   apiKey: "AIzaSyC6iMBsA9q9_KMjzLddKHJDljhQaHp3zW4",
//   authDomain: "social-motion-dev.firebaseapp.com",
//   databaseURL: "https://social-motion-dev.firebaseio.com",
//   projectId: "social-motion-dev",
//   storageBucket: "social-motion-dev.appspot.com",
//   messagingSenderId: "862317326827",
//   appId: "1:862317326827:web:40a67fceb5280ad1d6e9ae",
//   measurementId: "G-X0K25713MJ"
// };

// export const firebaseConfig = {
//   apiKey: process.env.API_KEY,
//   authDomain: process.env.AUTH_DOMAIN,
//   databaseURL: process.env.DATABASE_URL,
//   projectId: process.env.PROJECT_ID,
//   storageBucket: process.env.STORAGE_BUCKET,
//   messagingSenderId: process.env.MESSAGING_SENDER_ID,
//   appId: process.env.APP_ID
// };
export const firebaseConfig = {
  apiKey: "AIzaSyDf4B7FHLa3sFK5y0yBvPpqh2Iv4W3qU1k",
  authDomain: "social-motion-project.firebaseapp.com",
  databaseURL: "https://social-motion-project.firebaseio.com",
  projectId: "social-motion-project",
  storageBucket: "social-motion-project.appspot.com",
  messagingSenderId: "528128330389",
  appId: "1:528128330389:web:ec6a49ea6673b5a02de99c"
};

console.log(process.env.PROJECT_ID)

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const firestore = firebase.firestore();

export const auth = firebase.auth();
export const database = firebase.database();


export default firebase;