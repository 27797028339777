import produce from "immer";
import {FETCH_TEAM_DATA, FETCH_TEAM_DATA_ERROR, FETCH_TEAM_DATA_SUCCESS, SET_PRICE_DETAILS, SUBMIT_CONTRIBUTION, SUBMIT_CONTRIBUTION_ERROR, SUBMIT_CONTRIBUTION_SUCCESS} from "./actions";

const INIT_STATE = {
  teamDetails: [],
  teamDetailsIsLoading: false,
  teamDetailsError: {hasError: false, description: ''},
  selectedPrice: null,
  contributionSubmitting: false
};

export default function spendReducer(state = INIT_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_TEAM_DATA:
        draft.teamDetailsIsLoading = true;
        break;
      case FETCH_TEAM_DATA_SUCCESS:
        draft.teamDetails = action.payload;
        draft.teamDetailsIsLoading = false;
        draft.teamDetailsError = {hasError: false, description: ''};
        break;
      case FETCH_TEAM_DATA_ERROR:
        draft.teamDetails = [];
        draft.teamDetailsIsLoading = false;
        draft.teamDetailsError = {hasError: true, description: action.payload};
        break;
      case SET_PRICE_DETAILS:
        draft.selectedPrice = action.payload;
        break;
      case SUBMIT_CONTRIBUTION:
        draft.contributionSubmitting = true;
        break;
      case SUBMIT_CONTRIBUTION_SUCCESS:
        draft.contributionSubmitting = false;
        draft.selectedPrice = null;
        break;
      case SUBMIT_CONTRIBUTION_ERROR:
        draft.contributionSubmitting = false;
        draft.selectedPrice = null;
        break;
    }
  });
}
