/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  {id: "en", name: "English", direction: "ltr"},
  {id: "fr", name: "French", direction: "ltr"}
];

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/

export const isFullScreenActive = false;
export const isSearchActive = false;
export const isNavEasyAccessActive = false;
export const isMultiColorActive = false;
export const defaultColor = "light.green";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = false;
export const themeColorStorageKey = "__theme_color";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;
