import produce from "immer";
import {FETCH_ALL_TEAMS, FETCH_ALL_TEAMS_SUCCESS, FETCH_ALL_TEAMS_ERROR, JOIN_TEAM, JOIN_TEAM_SUCCESS, JOIN_TEAM_ERROR, DELETE_TEAM_SUCCESS, DELETE_TEAM_ERROR} from "./actions";

const INIT_STATE = {
  teamDetailsAll: [],
  teamDetailsALlIsLoading: false,
  teamDetailsAllError: {hasError: false, description: ''},
  joinTeamLoading: false,
};

export default function teamReducer(state = INIT_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_ALL_TEAMS:
        draft.teamDetailsALlIsLoading = true;
        break;
      case FETCH_ALL_TEAMS_SUCCESS:
        draft.teamDetailsAll = action.payload;
        draft.teamDetailsALlIsLoading = false;
        draft.teamDetailsError = {hasError: false, description: ''};
        break;
      case FETCH_ALL_TEAMS_ERROR:
        draft.teamDetailsAll = [];
        draft.teamDetailsALlIsLoading = false;
        draft.teamDetailsAllError = {hasError: true, description: action.payload};
        break;
      case JOIN_TEAM_SUCCESS:
        draft.joinTeamLoading = false;
        break;
      case JOIN_TEAM_ERROR:
        draft.joinTeamLoading = false;
        window.location.href = '/dashboard/teams';
        break;
      case JOIN_TEAM:
        draft.joinTeamLoading = true;
        break;
      case DELETE_TEAM_ERROR:
        window.location.href = '/dashboard/teams';
    }
  });
}
