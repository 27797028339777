import {call, put, select, takeEvery} from 'redux-saga/effects';
import firebase , {auth, database} from '../../services/firebase';
import moment from "moment";
import {
  FORGOT_PASSWORD,
  forgotPasswordError,
  forgotPasswordSuccess,
  GET_USER_DETAILS,
  getUserDetailsError,
  getUserDetailsSuccess,
  LOGIN_USER,
  loginUserError,
  loginUserSuccess,
  LOGOUT_USER,
  REGISTER_USER,
  registerUserError,
  registerUserSuccess, RESEND_VERIFY_EMAIL,
  RESET_PASSWORD,
  resetPasswordError,
  resetPasswordSuccess
} from './actions';
import axios from "axios";


//============================saga=1==========================================
const loginWithEmailPasswordAsync = async (email, password) =>
    await auth.signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

function* loginWithEmailPassword({payload}) {
  const {email, password} = payload.user;
  const {history} = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      localStorage.setItem('user_id', loginUser.user.uid);
      yield put(loginUserSuccess(loginUser.user));
      history.push('/');
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}
async function resendVerifyEmailAsync() {
  const token = await auth.currentUser.getIdToken();
  await axios.post('https://us-central1-social-motion-project.cloudfunctions.net/socialMotionAPI/users/resendverifyemail', {}, { headers: { Authorization: 'Bearer ' + token } });
}
//============================saga=6==========================================
async function getUserDetailsFromFirebase(userId) {
  const month = moment(new Date()).format('YYYY-MM');
  const monthPrev = moment(new Date()).subtract(1,'month').format('YYYY-MM');
  const date = moment(new Date()).format('YYYY-MM-DD');

  const weekArray = [];
  let today = moment(new Date());
  for (let i = 0; i<7; i++) {
    weekArray.push(today.format('YYYY-MM-DD'))
    today.subtract(1,'days')
  }


  const activity = await firebase.firestore().collection('Users').doc(userId).collection('Activities').where('date','==',date).get();
  const monthlyDUE = await firebase.firestore().collection('Users').doc(userId).collection('MonthlyDUEs').where('month','==',month).get();
  const monthlyDUEPrev = await firebase.firestore().collection('Users').doc(userId).collection('MonthlyDUEs').where('month','==',monthPrev).get();
  const weekData = await firebase.firestore().collection('Users').doc(userId).collection('Activities').where('date','in',weekArray).get();

  const weekDataDocs = [];
  for (const el of weekData.docs) {
    weekDataDocs.push(el.data())
  }

  let activityData = null
  let monthlyDUEData = null
  let monthlyDUEPrevData = null

  if (activity.docs.length !== 0) {
    activityData = activity.docs[0].data();
  }
  if (monthlyDUE.docs.length !== 0) {
    monthlyDUEData = monthlyDUE.docs[0].data();
  }
  if (monthlyDUEPrev.docs.length !== 0) {
    monthlyDUEPrevData = monthlyDUEPrev.docs[0].data();
  }
  return ({activity: activityData, monthlyDUE: monthlyDUEData, weekData: weekDataDocs, monthlyDUEPrev:monthlyDUEPrevData })
}

function* getUserDetails(action) {
  const userId = yield select(
      (state) => state.authReducer.user
  );
  try {
    const userDetails = yield call(getUserDetailsFromFirebase, action.userId);
    yield put(getUserDetailsSuccess(userDetails));
  } catch (error) {
    yield put(getUserDetailsError(error));
  }
}

//============================saga=3==========================================
const registerWithEmailPasswordAsync = async (data) => {
  const response = await axios.post('https://us-central1-social-motion-project.cloudfunctions.net/socialMotionAPI/users', data);

  return response;
}

function* registerWithEmailPassword({payload}) {
   try {
    yield call(registerWithEmailPasswordAsync, payload);
    // const registerUser = yield call(registerWithEmailPasswordAsync, data);
    // if (!registerUser.message) {
    //   localStorage.setItem('user_id', registerUser.user.uid);
    //   yield put(registerUserSuccess(registerUser));
    //   // history.push('/')
    // } else {
    //   yield put(registerUserError(registerUser.message));
    // }
    yield put(registerUserSuccess());

   } catch (error) {
     const { response } = error;
     yield put(registerUserError(response));
  }
}

//============================saga=2==========================================
const logoutAsync = async (history) => {
  await auth.signOut().then(authUser => authUser).catch(error => error);
  history.push('/')
};

function* logout({payload}) {
  const {history} = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem('user_id');
  } catch (error) {
    console.log("LOGOUT ERROR")
  }
}

//============================saga=4==========================================
const forgotPasswordAsync = async (email) => {
  return await auth.sendPasswordResetEmail(email)
  .then(user => user)
  .catch(error => error);
};

function* forgotPassword({payload}) {
  const {email} = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess("success"));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

//============================saga=5==========================================
const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await auth.confirmPasswordReset(resetPasswordCode, newPassword)
  .then(user => user)
  .catch(error => error);
};

function* resetPassword({payload}) {
  const {newPassword, resetPasswordCode} = payload;
  try {
    const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess("success"));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}
function* resendVerifyEmail() {
  yield call(resendVerifyEmailAsync);
}
function* getAuthSagas() {
  yield takeEvery(GET_USER_DETAILS, getUserDetails);
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
  yield takeEvery(LOGOUT_USER, logout);
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(RESET_PASSWORD, resetPassword);
  yield takeEvery(RESEND_VERIFY_EMAIL, resendVerifyEmail);
}

export const sagas = [getAuthSagas];
