import {call, put, takeLatest} from 'redux-saga/effects';
import {database, auth} from '../../services/firebase';
import {
    DELETE_TEAM,
    deleteTeamError,
    deleteTeamSuccess, JOIN_TEAM,
    joinTeamError,
    joinTeamSuccess,
    FETCH_ALL_TEAMS,
    fetchAllTeamsSuccess,
    fetchAllTeamsError,fetchAllTeams
} from "./actions";

import {getUserDetails} from "../auth/actions";
import axios from "axios";

//============================saga=1==========================================
async function deleteTeamsAsync(teamID) {
    const token = await auth.currentUser.getIdToken();
    // const response = await axios.delete('https://us-central1-social-motion-dev.cloudfunctions.net/socialMotionAPI/teams/'+teamID, { headers: { Authorization: 'Bearer ' + token } });
    const response = await axios.delete('https://us-central1-social-motion-project.cloudfunctions.net/socialMotionAPI/teams/'+teamID, { headers: { Authorization: 'Bearer ' + token } });
    return response;
}

async function joinTeamsAsync(teamID) {
    const token = await auth.currentUser.getIdToken();
    // const response = await axios.post('https://us-central1-social-motion-dev.cloudfunctions.net/socialMotionAPI/teams/join', {secret: teamID}, { headers: { Authorization: 'Bearer ' + token } });
    const response = await axios.post('https://us-central1-social-motion-project.cloudfunctions.net/socialMotionAPI/teams/join', {secret: teamID}, { headers: { Authorization: 'Bearer ' + token } });
    return response;
}

async function fetchAllTeamsAsync() {

    const teamSnaps = await database.ref("/teams/")
        .once("value");
    const teams = []
    for (const team in teamSnaps.val()) {
        const data = teamSnaps.val()[team];
        data.id = team;
        teams.push(data);
    }
    return teams;
}

function* deleteTeams(action) {
  try {
    yield call(deleteTeamsAsync, action.teamId);
    yield put(deleteTeamSuccess());
    yield put(fetchAllTeams());
    yield put(getUserDetails());
  } catch (error) {
      const {response} = error
    yield put(deleteTeamError(error));
  }
}

function* joinTeams(action) {
    try {
        yield call(joinTeamsAsync, action.teamId);
        yield put(joinTeamSuccess());
        yield put(fetchAllTeams());
        yield put(getUserDetails());
    } catch (error) {
        yield put(joinTeamError(error));
    }
}

function* fetchAllTeamsSaga(action) {
    try {
        const teamDetails = yield call(fetchAllTeamsAsync);
        yield put(fetchAllTeamsSuccess(teamDetails));
    } catch (error) {
        yield put(fetchAllTeamsError(error));
    }
}

function* getTeamSagas() {
  yield takeLatest(DELETE_TEAM, deleteTeams);
  yield takeLatest(JOIN_TEAM, joinTeams);
  yield takeLatest(FETCH_ALL_TEAMS, fetchAllTeamsSaga);
}

export const sagas = [getTeamSagas];
