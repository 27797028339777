import {call, put, select, takeLatest} from 'redux-saga/effects';
import {auth, database} from '../../services/firebase';
import moment from "moment";
import {
    GET_STEPS,
    getStepsError,
    getStepsSuccess,
    UPDATE_TRACKER,
    updateTrackerSuccess,
    updateTrackerError, UPDATE_UNIT, updateUnitError, updateUnitSuccess, updateLangSuccess, updateLangError, UPDATE_LANG
} from "./actions";
import { getUserDetails, gotFitbitData } from "../auth/actions";
import axios from 'axios';

//============================saga=1==========================================

async function getStepsAsync(token, userId) {
    let userData = await database.ref("/users/" + userId)
        .once("value")
        .then(function (snapshot) {
            return snapshot.val()
        })
        .catch(error => {
            console.log("Firebase DB Error", error);
        });

    const registeredDate = userData.dateRegistered;
    let stepData = userData.stepData;

    // const URL = `https://api.fitbit.com/1/user/-/activities/recent.json`;
    const URL2 = `https://api.fitbit.com/1/user/-/activities/steps/date/today/1m.json`;

    // const dataDays = await axios.get(URL, {headers: {Authorization: 'Bearer '+ token }});
    const dataMonth = await axios.get(URL2, {headers: {Authorization: 'Bearer '+ token }});

    const dateArray = dataMonth.data['activities-steps'];

    let weekArray = [];

    const startDay = dateArray[0].dateTime;

    let today = new Date(startDay);

    let goal = stepData[today.getFullYear().toString()][today.getMonth().toString()][today.getDate().toString()]['goal'] ? stepData[today.getFullYear().toString()][today.getMonth().toString()][today.getDate().toString()]['goal'] : 2000;


    while (today.getDay() !== 1) {
        today = moment(today).subtract(1,'day').toDate();
        const steps = stepData[today.getFullYear().toString()][today.getMonth().toString()][today.getDate().toString()]['stepCountAfterReg'];
        weekArray.push(steps? steps>15000 ? 15000 : steps : 0)
    }


    for (const el in dateArray) {


        if (new Date(dateArray[el].dateTime).getDay() === 1 && weekArray.length > 0) {
            let weekTotal = 0;

            for (const x of weekArray) {
                weekTotal = weekTotal + x;
            }
            const avg = (weekTotal/7) * 0.25;
            let count = 0;
            let goalTotal = 0;
            for (const y of weekArray) {
                if (y>avg) {
                    count = count + 1;
                    goalTotal = goalTotal + y;
                }
            }

            goal = goalTotal/count < 2000 ? 2000 : goalTotal/count;
            goal = goal * 1.1 > 10000? 10000 : goal * 1.1;
            weekArray = [];
        }

        weekArray.push(parseInt(dateArray[el].value, 10));

        await database.ref('users')
            .child(userId)
            .child('stepData')
            .child(new Date(dateArray[el].dateTime).getFullYear().toString())
            .child((new Date(dateArray[el].dateTime).getMonth()+1).toString())
            .child(new Date(dateArray[el].dateTime).getDate().toString())
            .set({stepCount:parseInt(dateArray[el].value, 10), stepCountAfterReg:new Date(dateArray[el].dateTime) > new Date(registeredDate)?parseInt(dateArray[el].value, 10) : 0, isSendNotification: false, goal: goal, earnedDUE:parseInt(dateArray[el].value,10)/goal})
        // console.log({stepCount:parseInt(dateArray[el].value, 10), stepCountAfterReg:new Date(dateArray[el].dateTime) > new Date(registeredDate)?parseInt(dateArray[el].value, 10) : 0, isSendNotification: false, goal: goal, earnedDUE:parseInt(dateArray[el].value,10)/goal})
    }

    userData = await database.ref("/users/" + userId)
        .once("value")
        .then(function (snapshot) {
            return snapshot.val()
        })
        .catch(error => {
            console.log("Firebase DB Error", error);
        });

    stepData = userData.stepData;
    let totalDue = 0;
    for (const year in stepData) {
        const months = stepData[year];
        for (const month in months) {
            const days = months[month];
            for (const day in days) {
                const earnedDue = days[day]['earnedDUE'] ? days[day]['earnedDUE'] : 0;
                totalDue = totalDue + earnedDue;
            }
        }
    }

    await database.ref('users')
        .child(userId)
        .child('earnedDUEs')
        .set(totalDue);

    return dataMonth;

}

async function updateTrackerAsync(tracker, userID) {
    const token = await auth.currentUser.getIdToken();

    const response = await axios.patch('https://us-central1-social-motion-project.cloudfunctions.net/socialMotionAPI/users', {currentTracker: tracker}, { headers: { Authorization: 'Bearer ' + token } });
    // const response = await axios.patch('https://us-central1-social-motion-dev.cloudfunctions.net/socialMotionAPI/users', {currentTracker: tracker}, { headers: { Authorization: 'Bearer ' + token } });

    return response;
}

async function updateUnitAsync(unit) {
    const token = await auth.currentUser.getIdToken();

    // const response = await axios.patch('https://us-central1-social-motion-dev.cloudfunctions.net/socialMotionAPI/users', {unit: unit}, { headers: { Authorization: 'Bearer ' + token } });
    const response = await axios.patch('https://us-central1-social-motion-project.cloudfunctions.net/socialMotionAPI/users', {unit: unit}, { headers: { Authorization: 'Bearer ' + token } });

    return response;
}

async function updateLangAsync(lang) {
    const token = await auth.currentUser.getIdToken();

    const response = await axios.patch('https://us-central1-social-motion-project.cloudfunctions.net/socialMotionAPI/users', {language: lang}, { headers: { Authorization: 'Bearer ' + token } });
    // const response = await axios.patch('https://us-central1-social-motion-dev.cloudfunctions.net/socialMotionAPI/users', {language: lang}, { headers: { Authorization: 'Bearer ' + token } });

    return response;
}
function* updateLanguage(action) {
    try {
        yield call(updateLangAsync, action.lang, action.userID);
        yield put(updateLangSuccess());
    } catch (e) {
        yield put(updateLangError(e.toString()))
    }
}
function* updateTracker(action) {
    try {
        yield call(updateTrackerAsync, action.tracker, action.userID);
        yield put(updateTrackerSuccess());
    } catch (e) {
        yield put(updateTrackerError(e.toString()))
    }
}
function* updateUnit(action) {
    try {
        yield call(updateUnitAsync, action.unit);
        yield put(updateUnitSuccess());
    } catch (e) {
        yield put(updateUnitError(e.toString()))
    }
}

function* getSteps(action) {
    const userDetails = yield select(
        (state) => state.authReducer.user
    );
    try {
        const steps = yield call(getStepsAsync, action.payload, userDetails);
        yield put(getUserDetails());
        yield put(gotFitbitData());
        yield put(getStepsSuccess(steps));
    } catch (error) {
        yield put(getStepsError(error.toString()));
    }
}

function* getStepsSagas() {
    yield takeLatest(GET_STEPS, getSteps);
    yield takeLatest(UPDATE_TRACKER, updateTracker);
    yield takeLatest(UPDATE_UNIT, updateUnit);
    yield takeLatest(UPDATE_LANG, updateLanguage);
}

export const sagas = [getStepsSagas];
