export const FETCH_TEAM_DATA = "FETCH_TEAM_DATA";
export const FETCH_TEAM_DATA_SUCCESS = "FETCH_TEAM_DATA_SUCCESS";
export const FETCH_TEAM_DATA_ERROR = "FETCH_TEAM_DATA_ERROR";
export const SET_PRICE_DETAILS = "SET_PRICE_DETAILS";
export const SUBMIT_CONTRIBUTION = "SUBMIT_CONTRIBUTION";
export const SUBMIT_CONTRIBUTION_SUCCESS = "SUBMIT_CONTRIBUTION_SUCCESS";
export const SUBMIT_CONTRIBUTION_ERROR = "SUBMIT_CONTRIBUTION_ERROR";

export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_ERROR = 'DELETE_TEAM_ERROR';

export const JOIN_TEAM = 'JOIN_TEAM';
export const JOIN_TEAM_SUCCESS = 'JOIN_TEAM_SUCCESS';
export const JOIN_TEAM_ERROR = 'JOIN_TEAM_SUCCESS';

export const FETCH_ALL_TEAMS = "FETCH_ALL_TEAMS";
export const FETCH_ALL_TEAMS_SUCCESS = "FETCH_ALL_TEAMS_SUCCESS";
export const FETCH_ALL_TEAMS_ERROR = "FETCH_ALL_TEAMS_ERROR";


export function deleteTeam(teamId) {
  return {
    type: DELETE_TEAM,
    teamId: teamId
  }
}
export function deleteTeamSuccess() {
  return {
    type: DELETE_TEAM_SUCCESS
  }
}
export function deleteTeamError(e) {
  return {
    type: DELETE_TEAM_ERROR
  }
}

export function joinTeam(teamId) {
  return {
    type: JOIN_TEAM,
    teamId: teamId
  }
}
export function joinTeamSuccess() {
  return {
    type: JOIN_TEAM_SUCCESS
  }
}
export function joinTeamError(e) {
  return {
    type: JOIN_TEAM_ERROR
  }
}

export function fetchAllTeams() {
  return {
    type: FETCH_ALL_TEAMS
  }
}

export function fetchAllTeamsSuccess(data) {
  return {
    type: FETCH_ALL_TEAMS_SUCCESS,
    payload: data
  }
}

export function fetchAllTeamsError(e) {
  return {
    type: FETCH_ALL_TEAMS_SUCCESS
  }
}