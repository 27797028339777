import {CHANGE_LOCALE} from '../actions';
export const GET_STEPS = 'GET_STEPS';
export const GET_STEPS_SUCCESS = 'GET_STEPS_SUCCESS';
export const GET_STEPS_ERROR = 'GET_STEPS_ERROR';
export const UPDATE_TRACKER = 'UPDATE_TRACKER';
export const UPDATE_UNIT = 'UPDATE_UNIT';
export const UPDATE_UNIT_SUCCESS = 'UPDATE_UNIT_SUCCESS';
export const UPDATE_UNIT_ERROR = 'UPDATE_UNIT_ERROR';
export const UPDATE_TRACKER_SUCCESS = 'UPDATE_TRACKER_SUCCESS';
export const UPDATE_TRACKER_ERROR = 'UPDATE_TRACKER_ERROR';
export const UPDATE_LANG = 'UPDATE_LANG';
export const UPDATE_LANG_SUCCESS = 'UPDATE_LANG_SUCCESS';
export const UPDATE_LANG_ERROR = 'UPDATE_LANG_ERROR';

export const changeLocale = (locale) => {
  localStorage.setItem('currentLanguage', locale);
  return (
      {
        type: CHANGE_LOCALE,
        payload: locale
      }
  )
};

export const getSteps = (token) => {
    return {
        type: GET_STEPS,
        payload: token
    }
}

export const getStepsSuccess = (data) => {
    return {
        type: GET_STEPS_SUCCESS,
        payload: data
    }
}

export const getStepsError = (data) => {
    return {
        type: GET_STEPS_ERROR,
        payload: data
    }
}

export const updateTracker = (tracker, userID) => {
    return {
        type: UPDATE_TRACKER,
        tracker: tracker,
        userID: userID
    }
}

export const updateUnit = (unit) => {
    return {
        type: UPDATE_UNIT,
        unit
    }
}

export const updateUnitSuccess = () => {
    return {
        type: UPDATE_UNIT_SUCCESS
    }
}

export const updateUnitError = (error) => {
    return {
        type: UPDATE_UNIT_ERROR
    }
}


export const updateTrackerSuccess = () => {
    return {
        type: UPDATE_TRACKER_SUCCESS
    }
}

export const updateTrackerError = (error) => {
    return {
        type: UPDATE_TRACKER_ERROR
    }
}

export const updateLang = (lang) => {
    return {
        type: UPDATE_LANG,
        lang
    }
}

export const updateLangSuccess = () => {
    return {
        type: UPDATE_LANG_SUCCESS
    }
}

export const updateLangError = (error) => {
    return {
        type: UPDATE_UNIT_ERROR
    }
}
