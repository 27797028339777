import produce from "immer";
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_ERROR,
  GET_USER_DETAILS_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
    GOT_FITBIT_DATA
} from './actions';

const INIT_STATE = {
  monthlyDUE: null,
  monthlyDUEPrev: null,
  userActivity: null,
  weekData:[],
  gotFitBit: false,
  user: localStorage.getItem('user_id'),
  userDetails: {},
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
  userDetailsISLoading: false,
  userDetailsError: {hasError: false, description: ''}
};

export default function authReducer(state = INIT_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_USER_DETAILS:
        draft.userDetailsISLoading = true;
        break;
      case GET_USER_DETAILS_SUCCESS:
        draft.userDetailsISLoading = false;
        draft.userActivity = action.payload.activity;
        draft.monthlyDUE = action.payload.monthlyDUE;
        draft.monthlyDUEPrev = action.payload.monthlyDUEPrev;
        draft.weekData = action.payload.weekData;
        draft.userDetailsError = {hasError: false, description: ''};
        break;
      case GET_USER_DETAILS_ERROR:
        draft.userDetailsISLoading = false;
        draft.userDetails = {};
        draft.userDetailsError = {hasError: true, description: action.payload};
        break;
      case LOGIN_USER:
        draft.loading = true;
        draft.error = '';
        break;
      case LOGIN_USER_SUCCESS:
        draft.loading = false;
        draft.user = action.payload.uid;
        draft.error = '';
        break;
      case LOGIN_USER_ERROR:
        draft.loading = false;
        draft.user = '';
        draft.error = action.payload.message;
        break;
      case FORGOT_PASSWORD:
        draft.loading = true;
        draft.error = '';
        break;
      case FORGOT_PASSWORD_SUCCESS:
        draft.loading = false;
        draft.forgotUserMail = action.payload;
        draft.error = '';
        break;
      case FORGOT_PASSWORD_ERROR:
        draft.loading = false;
        draft.forgotUserMail = '';
        draft.error = action.payload.message;
        break;
      case RESET_PASSWORD:
        draft.loading = true;
        draft.error = '';
        break;
      case RESET_PASSWORD_SUCCESS:
        draft.loading = false;
        draft.newPassword = action.payload;
        draft.resetPasswordCode = '';
        draft.error = '';
        break;
      case RESET_PASSWORD_ERROR:
        draft.loading = false;
        draft.newPassword = '';
        draft.resetPasswordCode = '';
        draft.error = action.payload.message;
        break;
      case REGISTER_USER:
        draft.loading = true;
        draft.error = '';
        break;
      case REGISTER_USER_SUCCESS:
        draft.loading = false;
        window.open('/user/login',)

        break;
      case REGISTER_USER_ERROR:
        draft.loading = false;
        draft.user = '';
        draft.error = action.payload.message;
        break;
      case LOGOUT_USER:
        draft.user = null;
        draft.error = '';
        break;
      case GOT_FITBIT_DATA:
        draft.gotFitBit = true;
        break;
    }
  });
}
