import {call, put, takeLatest} from 'redux-saga/effects';
import {database, auth} from '../../services/firebase';
import {
    FETCH_TEAM_DATA,
    SUBMIT_CONTRIBUTION,
    fetchTeamDataError,
    fetchTeamDataSuccess,
    submitContributionSuccess,
    submitContributionError
} from "./actions";
import axios from 'axios';

//============================saga=1==========================================
async function getTeamDetailsFromFirebase(teamIds) {
  return Promise.all(
      teamIds.map(teamId => {
        return database.ref("/teams/" + teamId)
        .once("value")
        .then(function (snapshot) {
            const data = snapshot.val();
            data.id = teamId;
          return data;
        })
        .catch(error => {
          console.log("Firebase DB Error", error, teamId);
        });
      })
  );
}

async function submitContributionsAsync(amount, priceID) {
    const token = await auth.currentUser.getIdToken();
    const response = await axios.post('https://us-central1-social-motion-project.cloudfunctions.net/socialMotionAPI/contributions', {amount: parseInt(amount,10),priceID: priceID}, { headers: { Authorization: 'Bearer ' + token } });
    // const response = await axios.post('https://us-central1-social-motion-dev.cloudfunctions.net/socialMotionAPI/contributions', {amount: parseInt(amount,10),priceID: priceID}, { headers: { Authorization: 'Bearer ' + token } });

    return response;
}

function* getTeamDetails(action) {
  try {
    const teamDetails = yield call(getTeamDetailsFromFirebase, action.payload);
    yield put(fetchTeamDataSuccess(teamDetails.filter(el => el.visible)));
  } catch (error) {

      yield put(fetchTeamDataError(error));
  }
}

function* submitContributions(action) {
    try {
        yield call(submitContributionsAsync, action.amount, action.priceID);
        yield put(submitContributionSuccess());
    } catch (e) {
        const { response } = e;
        yield put(submitContributionError(response));
    }
}

function* getSpendSagas() {
  yield takeLatest(FETCH_TEAM_DATA, getTeamDetails);
  yield takeLatest(SUBMIT_CONTRIBUTION, submitContributions);
}

export const sagas = [getSpendSagas];
